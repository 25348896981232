var routesData = [{
  name: 'dashboard',
  displayName: 'menu.dashboard',
  meta: {
    iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
    authorize: ['SYS_ADMIN', 'MSO_ADMIN'],
  },
},
{
  name: 'users',
  displayName: 'User',
  meta: {
    iconClass: 'fa fa-user',
    authorize: ['SYS_ADMIN'],
  },
  disabled: true,
},
{
  name: 'operatordetails',
  displayName: 'Operator Apps',
  meta: {
    iconClass: 'fa fa-tv',
    authorize: ['SYS_ADMIN'],
  },
  disabled: true,
},
{
  name: 'launcher',
  displayName: 'Launcher Apps',
  meta: {
    iconClass: 'fa fa-globe',
    authorize: ['SYS_ADMIN'],
  },
  disabled: true,
},
{
  name: 'backup',
  displayName: 'Backup',
  meta: {
    iconClass: 'fa fa-database',
    authorize: ['SYS_ADMIN'],
  },
  disabled: true,
},
// {
//   name: 'upgrade',
//   displayName: 'Upgrade',
//   meta: {
//     iconClass: 'fa fa-history',
//     authorize: ['SYS_ADMIN', 'MSO_ADMIN'],
//   },
//   disabled: true,
// },
]

var navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: routesData,
}

export { navigationRoutes }
